import React, {
  KeyboardEvent, EventHandler,
} from 'react';
import {
  styled, alpha,
} from '@mui/material/styles';
import {
  useNavigate,
} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';

const QuickSearch: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const keyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = (e) => {
    const term = (e.target as HTMLInputElement).value;
    if (e.key === 'Enter' && term.length >= 2) {
      navigate(`/search/${term}`);
    }
  };

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingBottom: 5,
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onKeyPress={keyPress}
        />
      </Search>
    </>
  );
};
export default QuickSearch;

import React from 'react';
import './App.css';
import {
  Authenticator, useAuthenticator,
} from '@aws-amplify/ui-react';
import {
  ThemeProvider,
  Button, Grid, Container,
} from '@mui/material';
import Logo from 'components/Logo/Logo';
import { useStyles } from 'App.jss';
import Adt from './components/Adt/Adt';
import awsconfig from './aws-exports';
import theme from './themes/themes';

const App: React.FunctionComponent = () => {
  const warning = [
    'Warning: You have accessed a private computer system. Unauthorized access, use, connection, or entry is not permitted and constitutes a crime punishable by law. ',
    'We reserve the right to fully pursue criminal and civil legal penalties. ',
    'All individuals using this computer system with or without proper authority are subject to having all their activities monitored and recorded. ',
    'Anyone using this system implicitly consents to this monitoring. ',
    'Any evidence of suspected criminal activity revealed by such monitoring may be provided to law enforcement officials.',
  ].join('');

  const generateSSOUrl = (provider:string):string => {
    const {
      domain,
    } = awsconfig.oauth;
    return [
      `https://${domain}/oauth2/authorize`,
      `?response_type=token&identity_provider=${provider}&client_id=${awsconfig.aws_user_pools_web_client_id}`,
      `&redirect_uri=https://${window.location.hostname}&scope=email+openid`,
    ].join('');
  };

  const ssoLogin = ():void => {
    window.open(generateSSOUrl('OliverTechnology'), '_self');
  };

  const ssoWellsLogin = ():void => {
    window.open(generateSSOUrl('wells'), '_self');
  };

  const styles = useStyles;

  const renderSSOButton = ():JSX.Element => {
    if (!window.location.href.includes('demo')) {
      return (
        <div>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={styles.sso}>
            <Button variant="contained" color="primary" sx={styles.ssoButton} onClick={() => ssoWellsLogin()}>WellsFargo Sign-In</Button>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={styles.sso}>
            <Button variant="contained" color="primary" sx={styles.ssoButton} onClick={() => ssoLogin()}>Oliver Sign-In</Button>
          </Grid>
        </div>
      );
    }
    return <></>;
  };

  const components = {
    SignIn: {
      Header() {
        return (
          <Container>
            <Grid container direction="row" justifyContent="space-between" sx={styles.logo}>
              <Logo variant="black" />
            </Grid>
          </Container>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();
        return (
          <ThemeProvider theme={theme}>
            <Container>
              { renderSSOButton() }
              <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={styles.sso}>
                <Button variant="contained" color="primary" sx={styles.ssoButton} onClick={() => toResetPassword()}>Reset Password</Button>
              </Grid>
            </Container>
          </ThemeProvider>
        );
      },
    },
    Footer() {
      return (
        <div>
          <br />
          {warning}
        </div>
      );
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator components={components} className="Authenticator">
        {({
          signOut, user,
        }) => (
          <Adt user={user} signOut={signOut} />
        )}
      </Authenticator>
    </ThemeProvider>
  );
};

export default App;


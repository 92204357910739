type RenderPDFPreviewOptions = {
  thumbnails?: boolean
  zoom?: string
  view?: string
};

const renderPDFPreview = (
  presignedUrl: string | undefined,
  {
    thumbnails,
    zoom,
    view,
  }: RenderPDFPreviewOptions = {},
): JSX.Element => {
  if (presignedUrl) {
    const options: string[] = [];

    let navpanes = 0;

    if (thumbnails) {
      navpanes = 1;
    }

    options.push(`navpanes=${navpanes}`);

    if (zoom) {
      options.push(`zoom=${zoom}`);
    }

    if (view) {
      options.push(`view=${view}`);
    }

    const optionsString = options.join('&');

    return (
      <div>
        <iframe
          // This key is needed as it prevents the src change from being pushed
          // to the browser history see #SXP-273 for details
          key={presignedUrl}
          style={{
            width: '100%', minHeight: '750px',
          }}
          title="preview"
          src={`${presignedUrl}#${optionsString}`}
        />
      </div>
    );
  }
  return <></>;
};

export default renderPDFPreview;

import React from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { OliDateFieldProps } from './OliDateField.types';

const OliDateField: React.FunctionComponent<OliDateFieldProps> = (props) => {
  const {
    label, value, disabled, error, required, handleChange,
  } = props;

  let dateValue: string | null = value;
  if (dateValue === undefined) {
    dateValue = null;
  }
  return (
    <>
      <DatePicker
        label={label}
        value={dateValue}
        onChange={handleChange}
        renderInput={(params) => <TextField variant="standard" {...params} disabled={disabled} error={error} required={required} />}
      />
    </>
  );
};

export default OliDateField;

import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import { AxiosResponse } from 'axios';
import {
  FillResponse, DocPrepResponse, CombineResponse, GetPresignedUrlResponse,
} from './DocPrep.types';

const getDoc = async (token: string | undefined, docId: string | undefined): Promise<AxiosResponse<DocPrepResponse> | undefined> => {
  if (!token) return undefined;

  const config = getConfig(token);
  const url = '/interview/list';
  const params = { id: docId };
  return OliAxios.get(url, {
    ...config, params,
  });
};

const mergePDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<FillResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/fillpdfform';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const concatenatePDFs = async (token: string | undefined, params: unknown): Promise<AxiosResponse<CombineResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = `${process.env.REACT_APP_API_URL}/documents/concatenate`;
  return OliAxios.post(url, params, config);
};

const approvePDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<FillResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/approve';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const rejectPDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<FillResponse> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/reject';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const deletePDF = async (token: string | undefined, params: unknown): Promise<AxiosResponse<unknown> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/documentmanagement/delete';
  return OliAxios.get(url, {
    ...config, params,
  });
};

const getPresignedUrl = async (token: string, bucket: string, key: string): Promise<AxiosResponse<GetPresignedUrlResponse>> => {
  const config = getConfig(token);
  const url = `${process.env.REACT_APP_API_URL}/documents/presigned`;
  return OliAxios.get(url, {
    ...config,
    params: {
      bucket,
      key,
    },
  });
};

export {
  getDoc, mergePDF, concatenatePDFs, approvePDF, rejectPDF, deletePDF, getPresignedUrl,
};

import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3e3842',
    },
    secondary: {
      main: '#ca304c',
    },
    info: {
      main: '#9d7fdb',
    },
  },
  typography: {
    body2: {
      fontSize: '0.75rem',
      '@media (min-width: 2000px)': {
        fontSize: '0.8125rem',
      },
    },
    subtitle1: {
      fontSize: '0.85rem',
      '@media (min-width: 2000px)': {
        fontSize: '1rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1100,
      lg: 1500,
      xl: 2000,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 4px',
        },
        head: {
          fontSize: 'inherit',
          fontWeight: 700,
        },
      },
    },
  },
});

export default theme;
